export const FAQ = {
  title: "مزال عندك اسألة؟",
  question: "تسمعش كلامنا، اسمع كلام آلاف الطلاب اللي يعملو في نتائج معانا",
  items: [
    {
      question: "نجم نقرى و نخدم فرد وقت؟",
      answer: (
        <>
          أكيد قصي عدا 3 سنين و هو يقرى و يخدم Freelance و كان ينجح كل عام. برشا
          منخرطين زادة يخدمو و يقراو.
        </>
      ),
    },
    {
      question: "كيف نخلص وقتاه نجم نستعمل المنصة؟",
      answer:
        "تاخوه في أقل من 24 ساعة و تنجم تبدى تتعلم من التليفون او الحاسوب و يبقالك مفعل مدى الحياة.",
    },
    {
      question: "شنوا نستحق بش نبدا نتعلم وندخل فلوس؟",
      answer: "بش تبدا في Freelance ماتستحق كان pc و internet",
    },
    {
      question: "لازم يكون عندي خبرة قبل ما ندخل؟",
      answer:
        "لا احنا نعلموك كل ماتستحق بش تدخل أول مليون من الانترنات. مايهمش بديت اليوم و الا حتى ماعندك حتى فكرة.",
    },
  ],
} as const;
