import LinearContainer from "@components/ui/linear-container/linear-container";
import { useState } from "react";
import { FAQ } from "src/content/FAQ";
import { cn } from "src/lib/utils";
import { v4 as uuidv4 } from "uuid";

interface IFAQ {
  title: React.ReactNode;
  sub: React.ReactNode;
  onClick?: () => void;
}

const FAQList = () => {
  return (
    <div className="flex flex-col gap-6">
      {FAQ.items.map((item) => {
        return (
          <FAQCard title={item.question} sub={item.answer} key={uuidv4()} />
        );
      })}
    </div>
  );
};

const FAQCard = (props: IFAQ) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };
  return (
    <LinearContainer noBottomBorder onClick={handleToggle}>
      <div
        dir="rtl"
        className="flex flex-col gap-6 px-4 md:px-6 lg:px-8 py-6 md:py-8 cursor-pointer select-none"
      >
        <div
          className={cn(
            "flex flex-col transition-[gap]",
            toggle ? "gap-6" : "gap-0"
          )}
        >
          <div className="flex justify-between items-center">
            <h4 className="font-geDinarone text-grey-w0 text-h5 md:text-h4">
              {props.title}
            </h4>
            <div className="relative w-6 h-6">
              <span className="block top-1/2 left-1/2 absolute bg-grey-w0 w-3.5 md:w-5 h-[1.5px] md:h-[2px] -translate-x-1/2 -translate-y-1/2" />
              <span
                className={cn(
                  "block top-1/2 left-1/2 absolute bg-grey-w0 w-3.5 md:w-5 h-[1.5px] md:h-[2px] transition -translate-x-1/2 -translate-y-1/2",
                  toggle ? "rotate-0" : "rotate-90"
                )}
              />
            </div>
          </div>
          <div
            className={cn(
              "grid transition-[grid-template-rows] overflow-hidden",
              toggle ? " grid-rows-[1fr]" : "grid-rows-[0fr]"
            )}
          >
            <p className="min-h-0 font-normal font-sora text-grey-w1 text-md md:text-xl antialiased">
              {props.sub}
            </p>
          </div>
        </div>
      </div>
    </LinearContainer>
  );
};

export default FAQList;
